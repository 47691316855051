@import '@/_styles/import';
.trigger {
  @include reset-button();
  @include font($size: rem(13), $weight: 500, $decoration: underline);

  display: flex;
  gap: 4px;
  align-items: center;

  padding: 2px 6px;
  border: 1px solid $white;
  border-radius: 2px;

  background: $lightwhite;
  box-shadow: 0 2px 4px 0 $black-012;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.icon {
  width: 20px;
  height: 20px;
  fill: $vv-red;
}

.portal {
  position: fixed;
  // Seat3d has 9999
  z-index: 10000;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 8px 24px;

  background: $lightwhite;
}

.overlay {
  position: fixed;
  z-index: 999;
  inset: 0;

  background-color: rgba($black, 0.8);

  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
