@import '@/_styles/import';
.link {
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  align-items: center;

  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: inherit;
  letter-spacing: 2px;

  &:global(._longText) {
    letter-spacing: 1px;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  svg {
    display: block;
    width: 28px;
    height: 17px;
    fill: var(--promobanner-cta-arrow-color, #{$vv-red});
  }
}

.button {
  overflow: hidden;

  width: fit-content;
  max-width: 100%;
  padding: 9px 19px;

  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-overflow: ellipsis;
  letter-spacing: 2px;

  &:global(._longText) {
    width: 100%;
    padding: 9px;
    letter-spacing: 0.5px;
  }
}
