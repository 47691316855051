@import '@/_styles/import';
.content.content {
  position: relative;

  overflow: hidden;

  width: 100vw;
  max-width: unset;
  height: 100vh; /* Fallback for older browsers */
  height: 100dvh;
  max-height: unset;
  border-radius: 0;

  background: linear-gradient(180deg, $maroon 0%, $pink-dark 100%);
  box-shadow: unset;
}

.trigger {
  @include reset-button();
  @include font($size: rem(13), $weight: 500, $decoration: underline);

  display: flex;
  gap: 4px;
  align-items: center;

  padding: 2px 6px;
  border: 1px solid $white;
  border-radius: 2px;

  background: $lightwhite;
  box-shadow: 0 2px 4px 0 $black-012;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.icon {
  width: 20px;
  height: 20px;
  fill: $vv-red;
}

.close {
  z-index: 99;
  top: 15px;
  right: 15px;
  left: unset;

  color: $white;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
