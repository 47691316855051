@import '@/_styles/import';
.root {
  @include media-breakpoint-down(md) {
    margin-bottom: 89px;
    padding-bottom: 30px;
  }
}

.headerText {
  margin-bottom: 16px;
  text-align: center;

  &.headerTextSmall {
    @include font($color: $gray-light, $name: $centra, $size: rem(17), $weight: 300, $line-height: rem(24));
  }

  @include font($color: $gray-light, $name: $centra, $size: rem(24), $weight: 300, $line-height: rem(30));

  @include media-breakpoint-down(md) {
    font-size: rem(20);
    line-height: rem(26.5);
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(17);
    line-height: rem(22.5);
  }
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.oneItemPerline {
  .itemsContainer {
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
