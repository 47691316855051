@import '@/_styles/import';
@keyframes loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.root {
  --base-color: #f7f7f7;
  --highlight-color: #fff;
  --animation-duration: 1.75s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  user-select: none;

  position: relative;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

  overflow: hidden;
  display: inline-flex;

  width: 100%;

  line-height: 1;

  background-color: var(--base-color);

  &.inDarkBg {
    --base-color: #fff;
    --highlight-color: #f7f7f7;
  }

  &.fill {
    position: absolute;
    inset: 0;
    font-size: 0;
  }

  @media (prefers-reduced-motion) {
    --pseudo-element-display: none; /* Disable animation */
  }
}

.root::after {
  content: ' ';

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateX(-100%);

  display: var(--pseudo-element-display);

  height: 100%;

  background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
  background-repeat: no-repeat;

  animation-name: loading-skeleton;
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: var(--animation-direction);
}
