@import '@/_styles/import';
.root {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 6px;
  align-items: center;

  height: 40px;
  padding: 0 16px 0 10px;

  background: $white;
  border: unset;
  border-radius: 20px;
}

.icon {
  width: 24px;
  fill: $vv-red;
}

.text,
.textShort {
  @include font($size: rem(15), $weight: 400, $line-height: 40px);

  color: $black;
}

.textShort {
  display: none;
}

@include media-breakpoint-down(md) {
  .text {
    display: none;
  }

  .textShort {
    display: block;
  }
}
