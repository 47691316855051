@import '@/_styles/import';
.root {
  z-index: $z-index-currency-dropdown;
  top: 34px;
  right: 30px;
  max-width: max-content;

  // Increase the specificity so the "Select" component styles do not have the opportunity to compete (if loaded later)
  &.root {
    position: absolute;
    gap: 0;
    height: 32px;
  }

  @include media-breakpoint-up(xxl) {
    right: calc(50% - #{0.5 * (1600px - 22px) - 30px});
  }

  @include media-breakpoint-down(lg) {
    top: 28px;
    right: 10px;
  }

  @include media-breakpoint-down(md) {
    top: 28px;
    right: 20px;
  }

  @include media-breakpoint-down(sm) {
    top: 15px;
    right: 10px;
  }

  :global(body.-with-f-m-agency) & {
    top: 88px;

    @include media-breakpoint-down(lg) {
      top: 83px;
    }

    @include media-breakpoint-down(md) {
      top: 82px;
    }

    @include media-breakpoint-down(sm) {
      top: 98px;
    }
  }
}

.portal {
  &.portal {
    z-index: $z-index-currency-dropdown;
  }
}

.option {
  display: flex;
  gap: 2px;
  place-items: center;
}
