@import '@/_styles/import';
.buttons {
  position: absolute;
  bottom: 11px;
  left: 11px;

  display: flex;
  gap: 8px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.pricing {
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 220px);
  justify-content: space-between;
  text-align: left;

  @include media-breakpoint-up(md) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-content: unset;
    text-align: center;
  }
}

.name {
  font-weight: 700;
}

.toggleGroup {
  display: flex;
  justify-content: center;

  width: fit-content;
  height: 56px;
  margin-inline: auto;
  padding: 4px;
  border-radius: 60px;

  background: $white;
  box-shadow:
    0 8px 48px 0 $black-008,
    0 1px 8px 0 $black-006;
}

.toggle {
  @include reset-button();

  padding: 4px 30px;
  border-radius: 60px;

  font-size: rem(13);
  line-height: 1.2;
  text-align: center;

  &[data-state='on'] {
    color: $white;
    background: $vv-red;
  }
}
