@import '@/_styles/import';
.root {
  @include font($size: rem(12), $weight: 500);

  display: flex;
  align-items: center;

  width: fit-content;
  min-height: 24px;
  padding: 4px 6px;
  border-radius: 12px;

  color: $purple;
  word-break: normal;
  overflow-wrap: anywhere;
}

.green {
  background-color: $light-green1;
}

.yellow {
  background-color: $urgency-layer-background;
}

.gray {
  background-color: $neutral-sea-mist;
}
