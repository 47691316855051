@import '@/_styles/import';
.root {
  position: sticky;
  z-index: 1;
  top: 0;

  display: none;

  margin: 20px 0;

  .toggleWrapper {
    display: flex;
    justify-content: space-around;

    height: 54px;
    padding: 4px;

    background: $white;
    border: 0.5px solid $gray-410;
    border-radius: 60px;

    &:has(> :last-child:nth-child(2)) {
      .toggleButton {
        width: 50%;
      }
    }

    &:has(> :last-child:nth-child(1)) {
      .toggleButton {
        width: 100%;
      }
    }
  }

  .toggleButton {
    width: 33.3%;
    background-color: transparent;
    border: 0;
    border-radius: 60px;

    &[data-state='on'] {
      background-color: $red;

      .buttonInner {
        color: $white;
      }
    }

    .buttonInner {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      justify-content: center;

      color: $black;

      .nameText {
        @include font($size: rem(14), $weight: 700, $line-height: rem(14));
      }

      :global(.amount) {
        font-size: rem(14);
        line-height: rem(14);
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}
