@import '@/_styles/import';
.root {
  box-sizing: border-box;
  min-height: rem(174);
  margin: 0 rem(49);
  padding-bottom: rem(40);

  color: $white;

  background: var(--promo-color-1, $purple);
  border: rem(1) solid var(--promo-color-1, $purple);
  border-radius: rem(8);

  @include media-breakpoint-down(md) {
    margin: 0 rem(25);
  }
}

.discount {
  display: flex;
  gap: 12px;
  padding: 0 rem(128);

  @include media-breakpoint-down(md) {
    padding: 0 rem(48);
  }

  @include media-breakpoint-down(sm) {
    padding: 0 rem(16);
  }

  &:not(:last-child) {
    margin-bottom: rem(10);
  }

  .icon {
    flex: 0 0 24px;
    width: 24px;
    fill: var(--promo-color-4, $urgency-layer-background);
  }

  .discountDesc {
    font-size: rem(15);
    line-height: rem(24);
    color: $gray-207;
  }
}

.eyebrow {
  margin: 0 auto;
  padding: rem(50) rem(80) rem(30);

  font-size: rem(17);
  font-weight: 500;
  line-height: rem(25);
  color: var(--promo-color-4, $light-green1);
  text-align: center;
  letter-spacing: 0;

  @include media-breakpoint-down(sm) {
    padding: rem(40) rem(49) rem(30);
  }
}

.titlesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  position: relative;

  width: fit-content;
  max-width: calc(100% - 200px);
  margin: 0 auto;

  font-size: rem(29);
  font-weight: bold;
  line-height: rem(34);
  color: $white;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: var(--promo-color-2, $blue-purple);
  text-decoration-thickness: rem(10);
  text-underline-offset: 3px;
  letter-spacing: 0;

  @include media-breakpoint-down(sm) {
    max-width: calc(100% - 130px);
    font-size: rem(25);
    line-height: rem(30);
  }

  &:last-child {
    margin-top: rem(10);
    margin-bottom: rem(40);

    @include media-breakpoint-down(sm) {
      margin-bottom: rem(30);
    }
  }
}

.title {
  position: relative;

  width: fit-content;
  margin: 0 auto;

  font-size: rem(29);
  font-weight: bold;
  line-height: rem(34);
  color: $white;
  letter-spacing: 0;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    font-size: rem(25);
    line-height: rem(30);
  }

  &::after {
    content: '';
    display: block;
    margin-top: rem(-15);
    border-bottom: rem(14) solid var(--promo-color-2, $blue-purple);
  }

  &:last-child {
    margin-top: rem(10);
    margin-bottom: rem(40);

    @include media-breakpoint-down(sm) {
      margin-bottom: rem(30);
    }
  }
}
