@import '@/_styles/import';
.mainContent {
  padding: 40px 60px;
  max-width: 1204px;

  @include media-breakpoint-down(md) {
    padding: 20px 24px;
  }
}

.narrow {
  max-width: 936px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

.header {
  margin-top: 16px;
  margin-bottom: 0;
  line-height: rem(31.5);
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
}

@mixin footerFixed() {
  position: fixed;
  bottom: 0;

  display: block;

  width: 100%;
  height: 89px;
}

.footer {
  display: none;
  padding: 20px 24px;
  background-color: $lightwhite;
  box-shadow:
    0 2px 8px 0 rgb(0 0 0 / 4%),
    0 4px 20px 0 rgb(0 0 0 / 9%);

  .selectButton {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    @include footerFixed();
  }
}

.presentation {
  .footer {
    padding: 16px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    @include footerFixed();
  }

  .mainContent {
    margin: 12px 12px 89px;

    @include media-breakpoint-down(xs) {
      margin: 4px 4px 89px;
    }
  }
}
