@import '@/_styles/import';
.root {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  font-size: rem(15);
}

.shipZoneImage {
  width: 133px;
}

.description {
  display: flex;
  flex-direction: column;
}

.sizeRow {
  display: flex;
  gap: 4px;
  align-items: center;

  min-width: 160px;
  min-height: 32px;
  padding-left: 8px;
  border-left: 1px solid $gray-010;

  .description > & + & {
    border-top: 1px solid $gray-010;
  }
}

.icon {
  width: 24px;
  height: 24px;
  padding: 2px;
  fill: $gray-410;

  .root.isVip & {
    fill: $brass-cabin;
  }
}
