@import '@/_styles/import';
.root {
  display: grid;
  grid-template-areas:
    'heading'
    'preview'
    'sizeDetails'
    'delimiter'
    'prices';
  gap: 24px;

  padding: 30px 20px;
  border: 1px solid $gray-223;
  border-radius: 4px;

  background: $white;
  box-shadow: 0 2px 4px 0 $black-008;

  @include media-breakpoint-up(md) {
    grid-template-areas:
      'heading sizeDetails'
      'preview preview'
      'delimiter delimiter'
      'prices prices';
    grid-template-columns: 1fr 1fr;

    .sizeDetails {
      justify-content: flex-end;
      align-self: flex-start;
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      'heading sizeDetails'
      'preview prices';

    &.single {
      grid-template-areas:
        'preview heading sizeDetails'
        'preview heading .'
        'preview heading prices';
      grid-template-columns: 2fr 1fr minmax(max-content, 1fr);

      .cabinName {
        display: none;
      }
    }

    .delimiter {
      display: none;
    }
  }
}

.heading {
  display: flex;
  flex-direction: column;
  grid-area: heading;
  gap: 12px;
}

.delimiter {
  grid-area: delimiter;
  background-color: $gray-010;
}

.prices {
  display: grid;
  grid-area: prices;
  gap: 24px;
  align-content: flex-start;

  text-align: center;

  @include media-breakpoint-up(md) {
    grid-template-columns: auto 1fr;
    text-align: left;
  }

  .single & {
    align-content: flex-end;

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr;
    }
  }
}

.sizeDetails {
  grid-area: sizeDetails;
}

.preview {
  grid-area: preview;

  .root:has(.upliftBlock) & {
    @include media-breakpoint-up(lg) {
      grid-area: 1 / 1 / 5 / 2;
    }
  }
}

.unavailable {
  filter: grayscale(1);
}

.upliftBlock {
  grid-area: -1 / 1 / -1 / -1;

  @include media-breakpoint-up(lg) {
    grid-area: -1 / 2 / -1 / 4;
    justify-content: flex-end;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(242 251 250 / 50%);
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  color: $black;
  gap: 5px;

  .logo {
    height: 14px;
  }

  .uplift.uplift {
    padding: 0;
  }
}
