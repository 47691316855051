@import '@/_styles/import';
.trigger {
  display: flex;
  column-gap: 1.2px;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  padding: 0;

  background-color: $white;
  border: 0.8px solid $gray-510;
  border-radius: 50%;

  &:hover {
    background-color: $gray-210;
  }

  &:focus-visible {
    border: 1px solid $white;
    outline: 2px solid $black;
  }
}

.dot {
  content: '';

  width: 3.68px;
  height: 3.68px;

  border: 0.8px solid $gray-510;
  border-radius: 50%;
}

.menuItem {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 108px;
  height: 108px;
  padding: 12px 12px 16px;

  background-color: $white;
  border: 0;
  border-radius: 8px;
  box-shadow:
    0 1px 8px 0 rgba($black-deep, 0.05),
    0 8px 48px 0 rgba($gray-410, 0.25);

  @include font(rem(12), $weight: 500, $line-height: rem(16), $color: $gray-510, $align: left);

  &:hover {
    color: $white;
    background-color: $gray-590;
  }
}

.menu {
  padding: 8px;

  &::before {
    content: '';

    position: absolute;
    z-index: -1;
    inset: 0 0 2px;

    background-color: rgba($gray-010, 0.5);
    border-radius: 8px;
    box-shadow: 0 3px 12px 0 rgba($black-deep, 0.05);
  }
}

.arrow {
  fill: rgba($gray-010, 0.5);
}
