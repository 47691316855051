@import '@/_styles/import';
.almostSoldOut {
  padding: rem(8) rem(12);

  font-size: rem(12);
  line-height: rem(16);
  color: $purple;

  background: rgba($pale-yellow-orange, 0.75);
  backdrop-filter: blur(4px);
  border-radius: 16px;

  @media (max-width: $container-xs-width) {
    padding: rem(4);
  }
}
