@import '@/_styles/import';
.root {
  position: relative;

  display: flex;
  flex-basis: 31.3%;
  width: 31.3%;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  align-self: stretch;

  max-width: 340px;
  padding: 24px;

  background: $white;
  border: 1px solid $gray-010;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 5%);

  @include media-breakpoint-down(md) {
    display: none;
    max-width: unset;
  }
}

.extendedWidth {
  flex-basis: 50%;
}

.showMobile {
  @include media-breakpoint-down(md) {
    display: block;
    flex-basis: 100%;
  }
}

.urgencyTag {
  position: absolute;
  top: 24px;
  left: 24px;

  @include font($size: rem(12), $weight: 500, $line-height: rem(16), $color: $purple);

  display: flex;
  gap: 4px;
  align-items: center;

  padding: 4px 8px;

  background: $pale-yellow-orange;
  border-radius: 15px;
}

.iconFeaturesContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: stretch;

  width: 100%;
  margin-top: 24px;
  padding: 24px 16px;

  background: $neutral-sea-mist;
  border-radius: 4px;

  .headline {
    @include font($size: rem(24), $weight: 400, $line-height: rem(33.5), $color: $black);

    margin: 0;

    :global(.fareClassHeadingRed) {
      font-weight: 600;
      color: $red;
    }
  }

  .iconFeatureItems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .iconFeatureItem {
      display: flex;
      gap: 8px;
    }
  }
}

.presentation {
  display: none;
  flex-basis: 100%;

  .iconFeaturesContainer {
    margin-top: 0;
  }

  &.showMobile {
    display: block;
  }

  .footer {
    display: none;
  }
}

.bordered {
  border: 2px solid $purple;
}

.textFeaturesContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;

  width: 100%;
  margin: 24px 0;

  .textFeatureItem {
    .textFeatureItemName {
      @include font($size: rem(15), $weight: 500, $line-height: rem(24), $color: $gray-light);
    }

    .textFeatureItemDescription {
      @include font($size: rem(13), $weight: 400, $line-height: rem(20), $color: $gray-light);
    }
  }
}

.footer {
  width: 100%;

  .selectCta {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}
