@import '@/_styles/import';
.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  white-space: nowrap;

  @include media-breakpoint-up(sm) {
    justify-content: flex-start;
  }
}

.accent {
  font-weight: bold;
  color: $vv-red;
}
