@import '@/_styles/import';
$content-width: calc(100% - var(--padding-x) * 2);
$max-content-width: calc(320px * 4 + var(--card-gap) * 3);

.root {
  --card-gap: 18px;
  --padding-x: 20px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-bottom: 40px;

  background-color: var(--promo-color-1, $vv-purple-light);
}

.backgroundMask {
  position: absolute;
  inset: 0 -1px -1px;

  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: calc(100% + 1px);
  background-size: cover;
}

.heading {
  --_title-line-height: 1.33em;

  position: relative;
  z-index: 0;

  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  width: min($content-width, $max-content-width);
  max-width: 1180px;
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 40px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: center;
  }

  &.wrapped,
  &:has(div:only-child) {
    justify-content: center;
  }

  .underlineWrapper {
    text-align: center;
  }

  .title {
    display: inline;

    padding-bottom: 0.5px;

    color: $white;

    background-image: linear-gradient(to right, var(--promo-color-2, $vv-red) 100%, var(--promo-color-2, $vv-red) 100%);
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 100% 15px;

    @include font($size: rem(24));

    @include media-breakpoint-up(lg) {
      --_title-line-height: 1.25em;

      @include font($size: rem(40));
    }
  }
}
