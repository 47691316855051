@import '@/_styles/import';
.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  :global {
    .MNVVOfferBanner {
      flex-direction: column;
      border-radius: 4px;

      &__info {
        flex-direction: column;
      }

      .early-redemption {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        padding-bottom: 16px;

        border-right: 0;
        border-bottom: 2px solid rgb(60 16 83 / 10%);

        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
          border: 0;
        }
      }
    }
  }
}
