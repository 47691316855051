@import '@/_styles/import';
.root {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: 4px;
  align-items: center;

  height: 40px;
  padding: 5px 5px 5px 16px;

  background-color: $white;
  border: 1px solid $gray-510;
  border-radius: 20px;
  outline: unset;

  @include font($size: 0.875rem, $color: $black, $weight: 700);

  &._smallPill {
    @include font($size: 0.875rem, $color: $black, $weight: 400);
  }

  &[data-applied='true'],
  &:focus-visible {
    padding: 4px 4px 4px 15px;
    border: 2px solid;
  }

  &[data-applied='true'] {
    border-color: $tropicalblue;
  }

  &:focus-visible {
    border-color: $black;
    outline: 0;
  }

  .dropdownIndicator {
    svg {
      width: 26px;
      height: 26px;
      color: $cabin-meta-color2;
      transition: transform 0.3s ease;
    }

    svg:hover {
      color: $grey-text;
    }
  }

  &[data-disabled='true'] {
    cursor: default;
    padding-inline: 0.75rem;

    .dropdownIndicator {
      display: none;
    }
  }

  &[aria-expanded='true'] .dropdownIndicator svg {
    transform: rotate(180deg);
  }
}

.hiddenLabel {
  display: none;
}

.value {
  // Disable text selection on trigger value
  user-select: none;

  overflow: hidden;
  flex: 1;

  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown {
  z-index: 910;

  max-width: 300px;
  max-height: 300px;
  margin-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;

  background-color: $white;
  border-radius: 4px;
  box-shadow:
    0 0 0 1px hsla(0deg 0% 0% / 10%),
    0 4px 11px hsla(0deg 0% 0% / 10%);

  &:focus {
    outline: 0;
  }
}

.option {
  cursor: pointer;

  margin: 3px 0;
  padding: 8px 12px;

  border: 2px solid transparent;
  outline: unset;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:global(._selected),
  &:global(._selected):hover {
    font-weight: 700;
    background-color: $blue-light;
  }

  &:global(._focused) {
    @include focusOutline();
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    font-weight: 500;
    background-color: $gray-010;
    border: 2px solid transparent;
    outline: unset;
  }
}
