@import '@/_styles/import';
.root {
  padding: 16px 48px 24px;
  background: $white-FC;
  border-top: 1px dashed $gray-010;
  border-radius: 0 0 4px 4px;

  @include media-breakpoint-down(sm) {
    padding: 12px 24px 24px;
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .heading {
    flex-grow: 1;

    @include font($color: $black, $size: rem(15), $weight: 400, $line-height: rem(24));

    :global(.fareClassHeadingRed) {
      font-weight: 700;
    }
  }

  .headingApplied {
    word-break: keep-all;

    @include font($color: $blue-purple, $size: rem(15), $weight: 500, $line-height: rem(24));
  }
}

.detailsButton {
  margin-top: 8px;

  @include font($color: $lightgrey2, $size: rem(13), $weight: 400, $line-height: rem(20));
}

.content {
  background-color: $lightwhite;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modalContainer {
  width: 560px;

  @include media-breakpoint-down(lg) {
    width: 452px;
  }

  @include media-breakpoint-down(sm) {
    width: calc(100% - 48px);
    height: calc(100% - 96px);
  }

  @media (max-width: 320px) {
    width: calc(100% - 16px);
    height: calc(100% - 32px);
  }
}

.modalOverlay {
  backdrop-filter: blur(13px);
}
