@import '@/_styles/import';
.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  :global(.amount) {
    @include font($size: rem(24), $weight: 400, $line-height: rem(33.5), $color: $black);
  }
}
