@import '@/_styles/import';
.VVRedCardWrap {
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.VVRedCard {
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 $card-box-shadow;
}

.imageWrp {
  display: flex;
  flex: 1 1 31.36%;
  aspect-ratio: 370 / 425;
  max-width: 370px;
}

.imageWrpSmall {
  aspect-ratio: 370 / 224;
  max-width: 370px;
  max-height: 224px;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.infoWrp {
  flex: 1 1 68.64%;
  padding: 32px 0 32px 24px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(2, auto);
}

.infoTxt {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  border-right: 0.75px solid $lavender-gray;
  grid-area: 1 / 1 / 2 / 2;
}

.infoTxtSmall {
  border-right: 0;
}

.heading {
  @include font($size: rem(28), $weight: 700, $color: $gray-light, $line-height: normal);
}

.details {
  margin-top: 8px;

  @include font($size: rem(18), $weight: 400, $color: $gray-light, $line-height: normal);
}

.billing {
  margin-top: 32px;
  padding: 16px;
  background-color: $coral-cint;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
}

.billingRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.billingCellLabel,
.billingCellValue {
  display: flex;
  flex-direction: row;
  gap: 13px;

  @include font($size: rem(18), $weight: 500, $color: $gray-light, $line-height: normal);
}

.total {
  margin-top: 10px;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
}

.totalLabel {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  row-gap: 8px;

  @include font($size: rem(24), $weight: 700, $color: $gray-light, $line-height: normal);
}

.totalAdults {
  @include font($size: rem(16), $weight: 500, $line-height: normal);
}

.totalValue {
  @include font($size: rem(28), $weight: 700, $color: $gray-light, $line-height: normal);
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
  list-style-type: none;
  padding-inline-start: 0;
  grid-area: 2 / 1 / 3 / 2;
  padding: 0 24px;
  border-right: 0.75px solid $lavender-gray;
}

.linksSmall {
  border-right: 0;
}

.link a {
  @include font($size: rem(14), $weight: 400, $color: $gray-light, $line-height: normal, $decoration: underline);

  overflow-wrap: anywhere;
}

.infoCTA {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  grid-area: 1 / 2 / 3 / 3;
  gap: 16px;
}

.infoCTASmall {
  .applyCta {
    color: $vv-red;

    &:hover {
      color: $red-dark;
    }
  }
}

.cta {
  border: 0;
  padding: 16px 30px;
  border-radius: 4px;
  background-color: $red;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;

  @include font($size: rem(12), $weight: 700, $color: $white, $line-height: normal, $spacing: 0.96px);
}

.cta.ctaSecondary {
  background-color: $white;
  border: 1px solid $red;
  color: $red;
}

@media (max-width: $container-lg1-max-width) {
  .infoWrp {
    padding: 20px;
    margin-top: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }

  .heading {
    font-size: rem(20);
  }

  .details {
    font-size: rem(14);
    margin-top: 20px;
  }

  .billing {
    margin-top: 30px;
  }

  .billingRow {
    gap: 20px;
  }

  .billingCellLabel {
    font-size: rem(16);
  }

  .VVRedCard {
    flex-direction: column;
  }

  .imageWrp {
    max-height: 177px;
    max-width: unset;
  }

  .image {
    border-radius: 8px 8px 0 0;
  }

  .infoTxt {
    padding: 0;
    border-right: 0;
    grid-area: 1 / 1 / 2 / 2;
  }

  .links {
    gap: 20px;
    padding: 20px 0 0;
    justify-content: space-between;
    grid-area: 3 / 1 / 4 / 2;
    border-right: 0;
  }

  .linksSmall {
    justify-content: center;
  }

  .totalValue {
    font-size: rem(20);
  }

  .totalLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .infoCTA {
    margin-top: 10px;
    grid-area: 2 / 1 / 3 / 2;
    flex-direction: row;
    align-items: stretch;
    padding: 0;
    gap: 24px;
  }

  .infoCTASmall {
    margin-top: 26px;
  }

  .cta {
    width: 100%;
  }

  .applyCta,
  .declineCta {
    flex: 1 1 50%;
  }
}

@media (max-width: $container-sm-max-width) {
  .infoCTA {
    flex-direction: column;
    gap: 16px;
  }

  .applyCta,
  .declineCta {
    flex: unset;
  }
}
