@import '@/_styles/import';
.top {
  position: fixed;
  z-index: $z-index-flyout;
  top: 0;
  right: 0;
  left: 0;

  border-bottom: 1px solid $lightgrey2;

  background: $lightwhite;

  @include media-breakpoint-up(sm) {
    padding: 10px;
  }
}

.bottom {
  position: fixed;
  z-index: $z-index-flyout;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 8px 24px;
  border: 1px solid $gray-010;

  background: $lightwhite;
  box-shadow: 0 2px 4px 0 $black-012;

  @include media-breakpoint-up(sm) {
    right: 40px;
    bottom: 20px;
    left: 40px;
    border-radius: 4px;
  }

  @include media-breakpoint-up(md) {
    right: 20px;
    bottom: 20px;
    left: unset;

    min-width: 280px;
    padding: 16px;
  }
}
