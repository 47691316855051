@import '@/_styles/import';
div.dialogContainer {
  @media (max-width: $container-xxxs-width) {
    inset: 0;
    transform: unset;
    animation: unset;
  }
}

/* TODO: MSH-120814 fix without !important when proper solution for MSH-121287 is there */
// slightly raising rule priority (over Dialog.Content rules) for proper application of rules in production
.dialogContent.dialogContent {
  width: 810px;
  max-width: 90vw;
  padding: 40px;
  background-color: $purple !important;

  @include media-breakpoint-down(xxs) {
    display: flex;
    align-items: center;

    width: 100vw;
    max-width: unset;
    height: 100vh;
    max-height: unset;

    border-radius: 0;
  }
}

// slightly raising rule priority (over Dialog.Content rules) for proper application of rules in production
.closeButton.closeButton {
  right: 10px;
  left: unset;
  color: $white-E9;
}

.heading {
  max-width: 480px;
  margin: 0 auto 35px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto 20px;
  }

  .title {
    @include font($size: rem(28), $color: $light-grey, $line-height: 1.35);
  }
}

@media (orientation: landscape) and (max-height: 700px) {
  .heading img {
    display: none;
  }
}

.promoCtaBlock {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  margin-bottom: 35px;

  @media (max-width: $container-lg1-max-width) {
    flex-direction: column;
  }
}

.link {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 12px 2px;

  letter-spacing: rem(1.5);

  border: 2px solid transparent;

  &:hover {
    color: $white;
  }

  &:focus-visible {
    border: 2px solid $white;
    outline: 2px solid $black;
  }

  :global(.Icon) {
    fill: $red;
  }

  @include font($size: rem(11), $weight: bold, $color: $white, $line-height: rem(20));
}

.linksContainer {
  min-width: 275px;
  max-width: 330px;
  margin: 0 auto;
  border-top: 1px solid $light-grey1;

  .link {
    position: relative;

    &::after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1px;

      background: $light-grey1;
    }
  }
}

.inlineCTA {
  position: relative;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 12px 2px;

  text-decoration: none;
  letter-spacing: 1.5px;

  border: 2px solid transparent;

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background: $light-grey1;
  }

  &:hover {
    color: $white;
    text-decoration: underline;
  }

  &:focus-visible {
    border: 2px solid $white;
    outline: 2px solid $black;
  }

  :global(.Icon) {
    fill: $red;
  }

  @include font($size: rem(11), $weight: bold, $color: $white, $line-height: rem(20));
}
