@import '@/_styles/import';
.WhatsIncludedRow {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;

  @media (min-width: $container-xs-max-width) {
    flex-direction: column;

    width: 45%;
    max-width: 280px;
    margin: 0;
    margin-bottom: 76px;

    text-align: center;

    .IncludeImage .image {
      margin-right: 0;
      margin-bottom: 18px;
    }
  }

  @media (max-width: $container-sm-max-width) {
    width: 43%;
  }

  @media (max-width: $container-xs-max-width) {
    width: 98%;
  }
}

.WhatsIncludedRowInline {
  flex: 1 0 30%;
  flex-flow: row;

  max-width: 332px;
  margin-bottom: 0;

  text-align: left;

  .IncludedBottomBorder {
    display: none;
  }

  .IncludeImage {
    display: flex;
    align-items: center;

    .image {
      margin-bottom: 0;
      width: 72px;
      height: 72px;
      margin-right: 0;
    }
  }

  .IncludeTextContent {
    margin-left: 8px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .featureName {
      @include font($size: rem(15), $weight: 'bold', $line-height: rem(24));

      margin-bottom: 0;
    }
  }

  .featureDescription {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(lg) {
    flex-basis: 34%;
  }

  @include media-breakpoint-down(md) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.WhatsIncludedRowFullWidth {
  width: 100%;
  max-width: 100%;
}

.IncludeImage .image {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.IncludeTextContent {
  .featureName {
    @include font($size: rem(17), $weight: 'bold', $line-height: rem(25));

    margin-bottom: 5px;
  }

  .featureDescription {
    @include font($size: rem(13), $line-height: rem(20));
  }
}

.IncludedBottomBorder {
  width: 45px;
  height: 0;
  margin: 0 auto;
  border-bottom: 2px solid $red;

  @media (max-width: $container-xs-max-width) {
    margin: 0;
  }
}
