@import '@/_styles/import';
.root {
  display: flex;
  gap: 32px;
  margin: 40px 0;
  justify-content: center;

  @include media-breakpoint-down(md) {
    margin: 20px 0;
  }

  &.presentation {
    margin: 0 0 20px;
  }
}
