@import '@/_styles/import';
.root {
  @include font($size: rem(20), $line-height: 1.5, $align: center);

  margin: 0;

  @include media-breakpoint-up(sm) {
    @include font($size: rem(30), $line-height: 1.2);

    text-align: left;
  }
}
