@import '@/_styles/import';
.title {
  margin: 0;

  @include font($size: rem(16), $weight: 700);

  @include media-breakpoint-up(md) {
    font-size: rem(18);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(20);
  }
}

.description {
  font-size: rem(15);
}

.unavailable {
  color: $gray-510;
}
