@import '@/_styles/import';
.root {
  display: grid;
  gap: 16px;
  align-items: center;
  text-align: center;

  @include media-breakpoint-up(md) {
    grid-auto-flow: column;
    gap: 24px;
    text-align: right;
  }
}

.price {
  display: grid;
  font-size: rem(12);
  white-space: nowrap;
}

.value {
  @include font($size: rem(20), $weight: bold, $line-height: 1.2);

  @include media-breakpoint-up(md) {
    font-size: rem(24);
  }
}

.root.isVip {
  .value {
    color: $brass-cabin;
  }
}

.root.discount {
  .value {
    color: $vv-red;
  }
}

.originalAmount {
  @include font($size: rem(16), $weight: 400, $color: $black, $decoration: line-through);
}
