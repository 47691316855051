@import '@/_styles/import';
.root {
  display: flex;
  align-items: top;
  justify-content: space-between;

  width: 100%;
  height: 90px;
  padding: 15px 10px 15px 15px;

  background-color: $white;

  :global(.loading-skeleton) {
    --base-color: #{$gray-110};

    display: flex;
    height: inherit;
    border-radius: inherit;
  }

  @include media-breakpoint-up(md) {
    align-items: center;
    padding: 0 20px 0 24px;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 10px;
  }

  @include media-breakpoint-up(xl) {
    height: 100px;
    padding: 0 30px;
  }
}

.start {
  width: 24px;
  height: 24px;
  margin-right: 71px;

  @include media-breakpoint-up(lg) {
    width: 14%;
    height: 30px;
    margin-right: 0;
  }
}

.end {
  width: 100px;
  height: 32px;
  border-radius: 16px;
}

.items {
  display: flex;

  .item {
    width: 50px;
    height: 60px;

    &:not(:first-child) {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    width: 52.5%;

    .item {
      width: 21%;
      height: 16px;

      &:not(:first-child) {
        display: block;
      }
    }
  }
}
