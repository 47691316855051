@import '@/_styles/import';
.root {
  display: flex;
  align-items: center;
  width: auto;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
}

.title {
  margin-right: 0.375rem;
  margin-bottom: unset;
  line-height: 24px;

  @include font($size: rem(15), $color: $grey-scale);
}

.select {
  width: 166px;

  &[data-applied='true'] {
    width: 168px;
  }

  @include media-breakpoint-up(xs) {
    width: 170px;

    &[data-applied='true'] {
      width: 172px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 199px;

    &[data-applied='true'] {
      width: 201px;
    }
  }
}
